import ShareTheMagic from 'components/ShareTheMagic';
import { NavButton } from '@/components/styled/Buttons';
import { analyticsTrack } from '@/util/analytics';
import { subscriptionPlans } from '@magicschool/supabase/user/utils';
import { Box } from '@magicschool/ui/Box';
import { GradientButton } from '@magicschool/ui/Buttons/GradientButton';
import { Divider } from '@magicschool/ui/Divider';
import { Grid } from '@magicschool/ui/Grid';
import { Tooltip } from '@magicschool/ui/Tooltip';
import { space } from '@magicschool/ui/constants/space';
import { zIndices } from '@magicschool/ui/constants/zIndices';
import { getBuildPrefix } from '@magicschool/utils/nextjs/metadata';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MagicStudentIntroModal } from 'app/(teacher)/rooms/MagicStudentIntroModal';
import { useStore } from 'features/store';
import useFeatureFlags from 'hooks/useFeatureFlags';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { memo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LocalizationSection from '../Header/LocalizationSection';
import ProfileSection from '../Header/ProfileSection';
import LogoSection from '../LogoSection';
import MenuList from './MenuList';
import MiniDrawerStyled from './MiniDrawerStyled';
import { PlanTag } from './PlanTag';
import { Spotlight } from './Spotlight';
const drawerSX = {
  paddingLeft: '0px',
  paddingRight: '0px',
  height: 'calc(99vh)'
};
const Sidebar = () => {
  const theme = useTheme();
  const {
    isDrawerOpen,
    toggleDrawer
  } = useStore(({
    SideBarStoreData: s
  }) => ({
    toggleDrawer: s.toggleDrawer,
    isDrawerOpen: s.isDrawerOpen
  }), []);
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const [showShareTheMagic, setShowShareTheMagic] = useState(false);
  const {
    magicStudentEnabled,
    planId
  } = useFeatureFlags();
  const handleOpenShareTheMagic = () => {
    analyticsTrack('Navigation:Action', {
      location: 'leftNav',
      value: 'Share the Magic'
    });
    setShowShareTheMagic(true);
  };
  const handleMagicStudentAILiteracy = () => {
    window.open('https://www.magicschool.ai/magicstudent', '_blank');
    analyticsTrack('Navigation:Action', {
      location: 'leftNav',
      value: 'Student AI Literacy'
    });
  };
  const handleLove = () => {
    window.open('https://love.magicschool.ai/all', '_blank');
    analyticsTrack('Navigation:Action', {
      location: 'leftNav',
      value: 'Love'
    });
  };
  const handleResources = () => {
    window.open('https://www.magicschool.ai/professional-development', '_blank');
    analyticsTrack('Navigation:Action', {
      location: 'leftNav',
      value: 'Training'
    });
  };
  const router = useRouter();
  const intl = useIntl();
  const drawerWidth = 260;
  const drawer = <Box sx={drawerSX}>
      <Box sx={{
      paddingLeft: 2,
      paddingRight: 2,
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      marginTop: 1
    }}>
        <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        paddingBottom: 3
      }}>
          <LogoSection />
        </Box>
        <MenuList />
        {magicStudentEnabled && <GradientButton component={Link} href="/rooms" onClick={() => {
        analyticsTrack('Navigation:Action', {
          location: 'leftNav',
          value: 'Launch to Students'
        });
      }}>
            <Box display="flex" data-test-id="student-rooms-button" gap={2} textAlign="start" width="100%" marginLeft={1} flexDirection="row" justifyContent="start" sx={{
          textWrap: 'wrap'
        }}>
              <Image width="18" height="18" style={{
            height: 'auto'
          }} src="/icons/rocket.svg" alt="Rocket Icon" />
              <FormattedMessage id="launch-to-students" />
            </Box>
          </GradientButton>}
        <Divider sx={{
        mt: 1.25,
        mb: 1.25
      }} />
        <Tooltip title={<Typography>
              <OpenInNewIcon fontSize="inherit" sx={{
          marginBottom: 0,
          marginRight: 1,
          padding: 0
        }} />
              <FormattedMessage id="tooltip.new-tab" />
            </Typography>} placement="right">
          <NavButton aria-label={intl.formatMessage({
          id: 'link.love'
        })} onClick={handleLove} sx={{
          width: '100%',
          ml: 0.5,
          mr: 0.5,
          pl: 1.5
        }}>
            <Image style={{
            marginRight: space(1.5)
          }} width="24" height="24" src="/icons/icon-sidebar-heart.svg" alt={intl.formatMessage({
            id: 'link.homepage'
          })} />
            <FormattedMessage id="love" />
          </NavButton>
        </Tooltip>
        <Tooltip title={<Typography>
              <OpenInNewIcon fontSize="inherit" sx={{
          marginBottom: 0,
          marginRight: 1,
          padding: 0
        }} />
              <FormattedMessage id="tooltip.new-tab" />
            </Typography>} placement="right">
          <NavButton aria-label={intl.formatMessage({
          id: 'link.homepage'
        })} onClick={handleResources} sx={{
          ml: 0.5,
          mr: 0.5,
          pl: 1.5
        }}>
            <Image style={{
            marginRight: space(1.5)
          }} width="24" height="24" src="/icons/icon-sidebar-resources.svg" alt={intl.formatMessage({
            id: 'link.homepage'
          })} />
            <FormattedMessage id="training" />
          </NavButton>
        </Tooltip>
        <div />
        <NavButton onClick={handleOpenShareTheMagic} sx={{
        ml: 0.5,
        mr: 0.5,
        pl: 1.5,
        textTransform: 'unset'
      }}>
          <Image style={{
          marginRight: space(1.5)
        }} width="24" height="24" src="/icons/icon-sidebar-wand.svg" alt="Magic Wand Icon" />
          <FormattedMessage id="share.the.magic.launch.button" />
        </NavButton>
        {magicStudentEnabled && <NavButton onClick={handleMagicStudentAILiteracy} sx={{
        ml: 0.5,
        mr: 0.5,
        pl: 1.5
      }}>
            <Image style={{
          marginRight: space(1.5)
        }} width="24" height="24" src="/icons/icon-sidebar-bunz.svg" alt="Bunny Mascot for MagicStudent" />
            <FormattedMessage id="ms-ai-literacy.button" />
          </NavButton>}
        {planId !== subscriptionPlans.PARTNER && <NavButton onClick={() => {
        // todo:add text should be pulled from dom?
        analyticsTrack('Subscription: Upgrade Link Click', {
          location: 'sidebar',
          text: 'Upgrade'
        });
        router.push('/subscription');
      }} sx={{
        ml: 0.5,
        mr: 0.5,
        pl: 1.5
      }}>
            <Image style={{
          marginRight: space(1.5)
        }} width="24" height="24" src="/icons/icon-potion.svg" alt="Potion Icon" />
            <FormattedMessage id="upgrade" />
          </NavButton>}
        <PlanTag />
        <Box sx={{
        flexGrow: 1
      }} />
        {matchUpMd && <Spotlight />}
        {matchUpMd && <>
            <Divider sx={{
          py: 0.5
        }} />
            <Grid container spacing={2} sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          padding: 1,
          paddingTop: 2
        }}>
              <Grid item>
                <LocalizationSection />
              </Grid>
              <Grid item>
                <ProfileSection />
              </Grid>
            </Grid>
          </>}
        {matchDownMd && <Typography fontSize={12} variant="body2" sx={{
        py: 0.5,
        color: '#adadad',
        textAlign: 'center'
      }}>
            build : {getBuildPrefix()}
          </Typography>}
      </Box>
    </Box>;
  return <Box component="nav" sx={{
    flexShrink: {
      md: 0
    },
    width: matchUpMd ? drawerWidth : 'auto'
  }} aria-label={intl.formatMessage({
    id: 'magicschool.menu'
  })} data-sentry-element="Box" data-sentry-component="Sidebar" data-sentry-source-file="index.tsx">
      {matchDownMd ? <Drawer variant={matchUpMd ? 'persistent' : 'temporary'} anchor="left" open={isDrawerOpen} onClose={toggleDrawer} sx={{
      '& .MuiDrawer-paper': {
        mt: matchDownMd ? 0 : 11,
        zIndex: zIndices.overlay,
        width: drawerWidth,
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderRight: 'none'
      }
    }} ModalProps={{
      keepMounted: true
    }} color="inherit">
          {drawer}
        </Drawer> : <MiniDrawerStyled variant="permanent" open sx={{
      display: matchDownMd ? 'none' : 'block'
    }}>
          {drawer}
        </MiniDrawerStyled>}
      {magicStudentEnabled && <MagicStudentIntroModal />}
      <ShareTheMagic open={showShareTheMagic} handleClose={() => setShowShareTheMagic(false)} data-sentry-element="ShareTheMagic" data-sentry-source-file="index.tsx" />
    </Box>;
};
export default memo(Sidebar);