'use client';

import { useStore } from '@/features/store';
import LAYOUT from '@/util/constant';
import MainLayout from 'layout/MainLayout';
import MaintenanceHeader from 'layout/MaintenanceHeader';
import MinimalLayout from 'layout/MinimalLayout';
interface LayoutProps {
  children: React.ReactNode;
  variant?: keyof typeof LAYOUT;
  bgOverride?: string;
  loading?: boolean;
}
export default function Layout({
  variant = LAYOUT.main,
  bgOverride,
  loading = false,
  children
}: LayoutProps) {
  console.info(`❤️ Made with love: ${process.env.NEXT_PUBLIC_VERCEL_URL}/api/love`);
  switch (variant) {
    case LAYOUT.minimal:
      return <>
          <MaintenanceHeader />
          <MinimalLayout>{children}</MinimalLayout>
        </>;
    case LAYOUT.noauth:
      return <>
          <MaintenanceHeader />
          <MinimalLayout>{children}</MinimalLayout>
        </>;
    default:
      {
        const showStudent = useStore(state => state.ToolsStoreData.showStudent, []);
        return <MainLayout bgOverride={showStudent ? '#d7d0f2' : bgOverride} loading={loading}>
          {children}
        </MainLayout>;
      }
  }
}