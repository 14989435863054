import VideoDialog from 'components/VideoDialog';
import { useStore } from 'features/store';
export const MagicStudentIntroModal = () => {
  const {
    magicStudentIntroModalOpen,
    setField,
    markAsRead
  } = useStore(({
    NotificationStoreData: s
  }) => ({
    magicStudentIntroModalOpen: s.magicStudentIntroModalOpen,
    setField: s.setField,
    markAsRead: s.markAsRead
  }), []);
  const close = () => {
    setField('magicStudentIntroModalOpen')(false);
    markAsRead('magic-student-intro');
  };
  return <VideoDialog open={magicStudentIntroModalOpen} onClose={close} titleId="ms-intro.title" videoUrl={process.env.NEXT_PUBLIC_MAGIC_STUDENT_INTRO_VIDEO_URL ?? ''} data-sentry-element="VideoDialog" data-sentry-component="MagicStudentIntroModal" data-sentry-source-file="MagicStudentIntroModal.tsx" />;
};